html {
	height: 100%;
}

body {
	-webkit-font-smoothing: antialiased;
	background: url('@{path-images}/bg.png') repeat;
	&.frontpage {
		background: #fff;
	}
}

#pageContainer {
	position: relative;

	// Move content down so the mobile header does not block it
	@media (max-width: @screen-sm-max) {
		padding-top: @header-height-mobile;
	}
}

#contentContainer {
	.clearfix();
	max-width: @site-width;
	margin-left: auto;
	margin-right: auto;

	body.forsida & {
		max-width: none;
	}
}

#main {
	.make-md-column(9);

	@media (max-width: @screen-xs-max) {
		.make-xs-column(12, 30px);
	}

	margin: 0 auto;
	max-width: 1200px;

	body.forsida & {
		.make-md-column(12, 0);
	}

	body.forsida &,
	body.hideright &,
	body.extras & {
		max-width: none;
		width: 100%;
	}
}

#sidebar {
	.make-md-column(3);

	body.hideright &, body.forsida & {
		&:extend(.hidden);
	}
}

#breadcrumbs {
	margin-top: 20px;
}
