a {
	.transition(color .33s);
}

img.responsive {
	.img-responsive();

	visibility: hidden;

	&.loaded {
		visibility: visible;
	}
}

h1 {
	margin-top: 70px;
	margin-bottom: 70px;

	font-size: 74px;
	font-family: @font-family-thirsty;
	text-align: center;
	color: @color-first;

	@media (max-width: @screen-sm-max) {
		font-size: 50px;
	}
	@media (max-width: @screen-xs-max) {
		font-size: 40px;
	}
}

h2 {

}

h3 {

}

h4 {
	font-size: 24px;
	font-weight: 700;
}

.btn {
	padding: 13px 25px;
	text-transform: uppercase;
	transition: .4s ease all;
	font-size: 18px;

	&:hover {
		background: @color-second;
	}
}

.btn-primary {
	font-family: @font-family-roboto-condensed;
	font-weight: 700;
}

div#auth {
	width: auto;
	padding: 40px;
	.boxText {
		margin-top: 100px;
		max-width: 400px;
		margin-right: auto;
		margin-left: auto;

	}
	dl {
		display: flex;
		max-width: 770px;
		margin-left: auto;
		margin-right: auto;
		flex-wrap: wrap;
		dd {
			flex: 1 1 50%;
			display: flex;
			margin-bottom: 10px;
			flex-direction: column;
			input {
				flex: 1;
				@media (min-width: @screen-sm) {
					border-left: none;
				}
				@media (max-width: @screen-xs-max) {
					border-top: none;
				}
			}
		}
		dt:not(:last-child) {
			flex: 0 0 120px;
			margin-bottom: 10px;
			label {
				margin: 0;
			}

			padding-left: 15px;
			padding-bottom: 10px;
			padding-top: 10px;
			color: #fff;
			background: @color-first;
			border: 1px solid @color-first;
			font-size: 20px;
			@media (min-width: @screen-sm) {
				border-right: none;
			}
		}
		input {
			border-color: @color-first;
		}
		@media (max-width: @screen-xs-max) {
			flex-wrap: wrap;
			dd,
			dt:not(:last-child) {
				flex: 1 1 100%;
				margin-bottom: 0;
			}

			dt:nth-of-type(2) {
				margin-top: 10px;
			}
		}
	}

	input.cancel,
	input.submit {
		.btn();
		.btn-primary();
	}
}