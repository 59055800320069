
//
//
///* Form fields
//-------------------------------------------------------------- */
//
//input, textarea, select, button {
//	margin-bottom:0.1em;
//	padding:4px;
//}
//
//input.text, input[type=text], input[type=password] {
//	border: 1px solid #ccc;
//}

//fieldset    { padding:1.4em; margin: 0 0 1.5em 0; border: 1px solid #ccc; }
//legend {
//	font-weight: bold;
//	padding: 0 1em;
//}

legend {
	font-size: 1.3em;
	font-weight: normal;
	padding-bottom: 10px;
	margin-top: 20px;
}

fieldset {
	margin-bottom: 20px;
}

label {
	font-weight: 400;
}

textarea {
	width: 100%;
	min-height:120px;
	border: 1px solid #ccc;
}

form dd {
	margin: 0 0 10px;
}

form ul.errors {
	list-style:none;
	border:1px solid #73000e;;
	width:50%;
	padding:4px 8px;
	font-size:10px;
	background:#f8caca;
	margin:0;
}

input[type="text"], input[type="password"], select, textarea {
	&:extend(.form-control);
	display: inline;
	width: auto;
	min-height: @input-height-base;
	min-width: 250px;
	max-width: 100%;
	.form-control-focus();
	.placeholder();

	&.headline {
		font-size: 1.3em;
		padding: 8px;
		width: 100%;
		min-height: @input-height-base + 5;
	}
}

textarea {
	height: auto;
}

select[multiple], select[size] {
	height: auto;
}

input[type="checkbox"], input[type="radio"] {
	margin-right: 10px;
}

.checkbox, .radio {
	display: inline;
	margin: 0;
}

.labelled {
	color: #999;
}

small.description {
	display: block;
	margin-top: 2px;
}

.page.admin .ui-tabs-panel fieldset .eType_checkbox {
	overflow: hidden;

	.lmnt {
		padding: 0 !important;
	}
	
	label {
		line-height: 20px;
	}
}

#main,
.moya_form {
	.elm {
		label {
			display: block;
		}
		&.elm_checkbox,
		&.elm.elm_radio,
		&.elm.eType_radio {
			.lmnt label {
				display: inline;
			}
		}
		&.elm_checkbox,
		&.elm.eType_checkbox {
			label {
				display:inline;
			}
		}
	}
}
