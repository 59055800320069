#news {
  .news_autopublish,
  .news_unpublished,
  .news_calendaronly,
  .news_sticky {
    padding: 0 5px;
    margin: 0 5px 0 0;
    background: #33a;
  }
  .news_unpublished {
    background: #a33;
  }
  .news_calendaronly {
    background: #aa3;
  }
  .news_sticky {
    background: #3aa;
  }
}

#newstabs textarea {
  width: 100%;
  min-height: 100px;
}

#newstabs_stillingar {
  .options {
    input {
      display: block;
      float: left;
    }

    label {
      display: block;
    }
  }

  .autodate {
    label {
      margin-top: 10px;
    }
  }
}
