#footerInfo {
	.clearfix;
	position: relative;
	margin-top: 50px;
	@media (min-width: @screen-md) {
		height: 430px;
	}

	background: @color-first;
	color: #fff;
	box-shadow: 1px 1px 1px 1px #000;
	font-family: @font-family-roboto;

	.frontpage & {
		margin-top: 0;
	}

	// Shadow
	&:before {
		content: '';
		position: absolute;
		top: 0;
		z-index: 1;
		left: 0;
		right: 0;
		display: block;
		height: 10px;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), transparent);
	}

	.info {
		.make-sm-column(6);
		padding-top: 50px;
		padding-bottom: 50px;
		position: relative;
		max-width: 800px;
		z-index: 1;
		float: right;

		h4 {
			font-size: 30px;
			text-transform: uppercase;
		}

		ul {
			list-style: none;
			padding-left: 0;
			margin-bottom: 40px;
		}

		a {
			color: #fff;

			&:hover {
				color: @color-third;
			}
		}

		.jip {
			position: absolute;
			top: 15px;
			left: 15px;
			right: 15px;
			a {
				color: #333;

				&:hover {
					color: @color-third;
				}
			}
		}

		@media (max-width: @screen-xs-max) {
			float: none;
		}
	}

	a[href*="facebook.com"] {
		opacity: 0.5;
		font-style: italic;
		color: #fff;
		transition: .4s ease all;
		font-weight: 400;

		&:before {
			.fa-icon;
			content: @fa-var-facebook-f;
			height: 60px;
			width: 60px;
			margin-right: 20px;
			border-radius: 50%;
			background: #fff;
			font-size: 28px;
			text-align: center;
			transition: .4s ease all;
			line-height: 64px;
			color: @color-first;
		}

		&:hover {
			opacity: 1;
			color: #fff;
		}
	}

	.first-wrap {
		position: relative;
		height: 100%;
		width: 60%;
		float: left;

		@media (min-width: @screen-md) {
			padding-right: 15px;
		}

		@media (max-width: @screen-sm-max) {
			width: 100%;
			height: auto;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: url("@{path-images}footer-bg.jpg");
			background-position: left center;
			background-size: cover;
			opacity: 0.1;
		}
	}

	.inner-wrap {
		height: 100%;
		width: 800px;
		max-width: 100%;
		float: right;
	}

	.badges {
		padding-top: 150px;
		.make-sm-column(6);
		ul {
			.clearfix();
			display: flex;
			justify-content: space-around;
			list-style: none;
			padding-left: 0;
			margin-bottom: 0;
			text-align: center;
			li {
				flex: 1;
				max-width: 130px;
			}
		}
		@media (max-width: @screen-xs-max) {
			padding-top: 70px;
		}
	}

	#map {
		width: 40%;
		height: 100%;
		float: left;

		@media (max-width: @screen-sm-max) {
			width: 100%;
			height: 430px;
		}

		@media (max-width: @screen-xs-max) {
			height: 400px;
		}
		.moyaMap {
			height: 100%;
			flex: 1;

			*[class*=leaflet] {
				z-index: inherit;
			}

			.leaflet-tile-pane {
				filter:saturate(5%);
			}

			.leaflet-control-container {
				.leaflet-left {
					right:0;
				}

				.leaflet-control-fullscreen {
					position: absolute;
					right: 10px;
				}
			}
		}
	}
}

#footerContent {
	max-width: @site-width;
	margin: 0 auto;
	position: relative;
	min-height: 50px;
}