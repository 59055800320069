#owl-hero {
	position: relative;
	height: 60vh;
	overflow: hidden;

	.item {
		position: relative;
		height: 60vh;
		width: 100%;
		overflow: hidden;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		backface-visibility: hidden;
		transform: translateZ(0) scale(1.0, 1.0);

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			display: block;
			height: 0;
			box-shadow: 0 1px 5px 2px rgba(0,0,0,0.3);
		}
	}

	@media (min-width: 1500px) {
		height: 590px;
		.item {
			height: 590px;
		}
	}

	@media (max-width: @screen-sm-max) {
		height: 40vh;
		.item {
			height: 40vh;
		}
	}

	@media (max-width: @screen-xs-max) {
		height: 20vh;
		.item {
			height: 20vh;
		}
	}

	.owl-nav {
		display: block !important;
	}

	.owl-dots {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 20px;
		text-align: center;

		@media (max-width: @screen-sm-max) {
			bottom: 15px;
		}

		.owl-dot {
			position: relative;
			display: inline-flex;
			width: 20px;
			height: 20px;
			margin-right: 10px;
			border-radius: 50%;
			background: #fff;
			transition: .4s ease all;

			&.active {
				&:before {
					content: '';
					position: absolute;
					top: 4px;
					left: 4px;

					display: block;
					width: 12px;
					height: 12px;

					border-radius: 50%;
					background: @color-first;
				}
			}

			span {
				display: none;
			}

			&:hover {
				opacity: 0.8;
			}
		}
	}
}
