/*gallery*/
#gallery #albumName-element, #gallery #albumName-label,
#gallery #active-label, #gallery #potd-label {
	display:none;
}
#gallery #albumName-element, #gallery #albumName-label,
#gallery #albumId-label,
#gallery #active-element, #gallery #potd-element {
	float:left;
	margin-right:8px;
}
#gallery #albumId-element {
	margin-right: 8px;
}

#gallery #file-label, #gallery #zfile-label, #gallery #ffile-label, #gallery #controls-label{
	clear:left;
}
#gallery #preview {
	margin-bottom:10px;
	float:left;
	text-align:center;
}
#gallery #imageManip .view {
	float:left;
	margin-right:10px;
	text-align:center;
	font-weight:700;
	font-size:11px;
}
#gallery #imageManip .controls {
	margin-top:10px;
	float:left;
}
#gallery #ffileList {
	ul {
		padding:0;
	}
	li {
		list-style:none;
		display:inline-block;
		padding:3px;
		margin:5px;
		border:1px solid #e3e3e3;
		width:64px;

		img {
			width:100%;
		}
	}
}

#galleryEditMultiple {
	margin-bottom: @line-height-computed;
	img{
		.img-responsive;
		margin-bottom: 10px;
	}

	td {
		vertical-align: top;
		border-bottom: 1px solid @color-border;
		padding:10px;

		&:first-child {
			width: 80%;
		}
	}

	label {
		margin-right: 30px;
	}

	input.text {
		width: 100%;
	}

	input[type=checkbox] {
		margin-right: 0px;
	}

	.info {
		font-size: 13px;
	}

	span.infoIcon {
		position:absolute;
		margin-top:4px;
		left:405px;
		z-index: 100;
		background:url(/_moya/icons/Info.png) no-repeat;
		width:16px;
		height:16px;
	}
}


#gallery.backend img {
	border:0;
}
#gallery #sortList {
	margin:0;
	padding:0;
}
#gallery #sortList.horizontal li {
	margin: 5px;
}
#gallery #sortList.horizontal li img {
	height:92px;
}
#gallery .grid th.cover,
#gallery .grid td.cover {
	text-align: center;
}