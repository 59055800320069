#sidebar .box_sitemap {
	margin-top: 220px;
	.hidden-xs;
	.hidden-sm;

	.mmLevel3 {
		padding: 5px 0;
		display: none;
	}

	.level2 {
		position: relative;
		padding: 8px 0 7px;

		border-bottom: 1px solid @color-border;
		overflow: hidden;

		font-size: 22px;
		font-weight: 600;

		&:first-child {
			border-top: 1px solid @color-border;
		}

		> a {
			color: @color-first;
			&:hover {
				color: @color-second;
			}
		}

		&.selectedLevel2 {
			> a {
				color: @color-second;
				font-weight: 500;
			}

			&:before {
				content: '';
				display: block;
				height: 8px;
				position: absolute;
				opacity: 0.1;
				left: 0;
				right: 0;
				top: -5px;
				background: radial-gradient(#000, transparent);
			}

			&:after {
				content: '';
				display: block;
				height: 8px;
				position: absolute;
				opacity: 0.1;
				left: 0;
				right: 0;
				bottom: -5px;
				background: radial-gradient(#000, transparent);
			}
		}
	}
}