// Hide boxTitle
.boxNoTitle() {
	.boxTitle {
		display:none;
	}
}

.resetJip() {
	.jip {
		background: none;
		border: 0;
		border-radius: 0;
		margin: 0;
		padding: 0;

		a {
			.text-hide();
			margin: 0;
			padding: 0;

			img {
				margin: 0;
			}
		}
	}
}

//Multiline ellipsis
.ellipsis(@lines-to-show:3; @font-size:@font-size-base; @line-height:@line-height-base) {
	display: block;
	display: -webkit-box;
	height: @font-size*@line-height*@lines-to-show;
	font-size: @font-size;
	line-height: @line-height;
	-webkit-line-clamp: @lines-to-show;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

// Shorthand for FontAwesome icon
// ex: .fontAwesome(@fa-var-search);
.fontAwesome(@icon) {
	.fa-icon();
	&:before {
		content: @icon;
	}
}

.fa-before(@icon) {
	&:before {
		content:@icon;
		font:normal normal normal 14px/1 FontAwesome;
	}
}

@highdpi: ~"(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)";

.at2x(@path, @w: auto, @h: auto) {
	background-image: url(@path);
	@at2x_path: ~`@{path}.replace(/\.\w+$/, function(match) { return "@2x" + match; })`;

	@media @highdpi {
		background-image: url("@{at2x_path}");
		background-size: @w @h;
	}
}

.border-radius(@r) {
	-webkit-border-radius:@r;
	-moz-border-radius:@r;
	border-radius:@r;
}

.transform(@transform){
	-webkit-transform: @transform;
	-moz-transform: @transform;
	-ms-transform: @transform;
	-o-transform: @transform;
	transform: @transform;
}