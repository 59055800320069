/** JQUERY UI **/
.ui-tabs {
	margin-bottom:1em;
}
.sortable {
 	list-style:none;
    margin:0;
    overflow:auto;
    padding:10px;
    border:1px solid #e3e3e3;
 }
.sortable.horizontal li {
	float:left;
}