#extras {
	.category {
		.items {
			.make-row();
			display: flex;
			flex-wrap: wrap;

			.item {
				.polar-card();
				display: flex;
				padding-left: 15px;
				padding-right: 15px;
				margin-bottom: 30px;
				flex: 0 0 100/3%;
				flex-direction: column;
				.inner {
					cursor: pointer;
					p:last-child {
						margin-top: 25px;
						font-size: 19px;
					}

					h3 {
						margin-bottom: 25px;
					}

					.info {
						display: flex;
						text-shadow: none;
						flex-direction: column;
						padding-bottom: 0;
						flex: 1;
						font-size: 17px;

						.description {
							flex: 1;
						}
					}

					.more {
						display: inline-block;
						padding: 8px 19px;
						font-size: 18px;
						font-family: @font-family-roboto-condensed;
						font-weight: 700;
						text-transform: uppercase;
						opacity: 0;
						background: @color-first;
						transition: .4s ease all;
						transform: translateY(0);
					}
					img {
						max-width: 100%;
					}
					&:hover {
						.more {
							opacity: 1;
							transform: translateY(21px);
						}
					}
				}
			}

			@media (max-width: @screen-sm-max) {
				margin-left: -10px;
				margin-right: -10px;
				.item {
					flex: 0 0 50%;
					padding-left: 10px;
					padding-right: 10px;
					margin-bottom: 20px;
					.inner {
						.info {
							padding-bottom: 30px;

							h3 {
								font-size: 26px;
							}
						}
						.more {
							display: none;
						}
					}
				}
			}

			@media (max-width: @screen-xs-max) {
				flex-wrap: wrap;
				.item {
					flex: 0 0 100%;
				}
			}
		}
	}

	.item {
		.text-wrap {
			.make-row();

			.description {
				.make-md-column(8);
				&.full {
					.make-md-column(12);
				}
				ul {
					.arrow-list();
				}

				.info-list-wrap {
					@media (min-width: @screen-sm) {
						width: 50%;
						float: left;
						padding-right: 30px;
						&:nth-child(2n+1) {
							clear: left;
						}
					}
					margin-bottom: 30px;
				}
			}

			.info {
				.make-md-column(4);
				@media (max-width: @screen-sm-max) {
					clear: left;
				}

				.table-responsive {
					border: none;
				}

				table {
					width: 100%;
					font-size: 17px;
					color: #fff;
					background: #27b5e5;
					border-collapse: collapse;
					p {
						margin-bottom: 0;
					}
					tr:not(:first-child) {
						border-top: 1px solid #65bddb;
					}
					th,
					td {
						padding: 15px 20px;
						&:first-child {
							background: #23a1cc;
							font-weight: 700;
						}
						&:last-child {
							font-weight: 600;
						}
					}
				}
				.btn {
					margin-top: 25px;
					display: block;
				}
				@media (max-width: @screen-sm-max) {
					margin-top: 50px;
				}
			}
		}

		.images {
			margin-top: 110px;
			margin-bottom: 50px;
			.make-row();
			.image {
				.make-xs-column(6);
				.make-md-column(3);
				margin-bottom: 30px;
				transition: .4s ease all;
				img {
					width: 100%;
				}
				&:hover {
					opacity: .85;
				}

				@media (max-width: @screen-xs-max) {
					&:nth-child(2n+1) {
						padding-right: 10px;
					}

					&:nth-child(2n) {
						padding-left: 10px;
					}
				}
			}

			@media (max-width: @screen-xs-max) {
				margin-top: 50px;
				.image {
					margin-bottom: 20px;
				}
			}
		}
	}
}