.fancybox-skin {
	padding: 10px!important;
	.border-radius(0);
}
.fancybox-nav {
	&.fancybox-prev span {
		left: 0;
		.fa-before(@fa-var-angle-left);
	}
	&.fancybox-next span {
		right:0;
		.fa-before(@fa-var-angle-right);
	}
	span {
		width: 50px;
		height: 50px;
		margin-top: -25px;
		background: #fff;
		visibility:visible;
		.opacity(.7);
		.transition(all 0.4s ease);
		&:before {
			font-size:30px!important;
			color:#000;
			text-align: center;
			display: block;
			padding: 8px 0 0;
		}
	}
	&:hover {
		span {
			.opacity(1);
		}
	}
}
.fancybox-title {
	font-size:16px;
	font-family:@font-family-base;
	color:#000;
	padding: 25px 10px 20px;
}