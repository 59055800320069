.strevda {
	ul {
		list-style: none;
		margin: 0;
	}

	li {
		position: relative;
	}

	.typetag {
		font-size: 9px;
		text-transform: uppercase;
		background: #eee;
		background: rgba(0, 0, 0, 0.1);
		color: #666;
		padding: 1px 4px;

		position: absolute;
		top: -14px;
		left: 0;
	}

	.typetag.type2 {
		background: #ff9;
		background: rgba(255, 255, 120, 0.4);
		color: #990;
	}
}
