@color-text-light: #999;

#news {
	h2 {
		margin-top: 0;
		margin-bottom: 0;
	}

	.entries {
		.entry {
			br.clear {
				display: none;
			}
		}
	}

	.entry {
		.clearfix();
		padding-bottom: @line-height-computed;
		border-bottom: 1px solid #ccc;
		margin-bottom: @line-height-computed;
	}

	.entryContent {
		clear: none;
	}

	.entryInfo {
		font-size: 14px;
		color: @color-text-light;
		margin-bottom: 5px;
	}

	.entryImage {
		&.thumb {
			float: left;
			width: 25%;
			margin-right: @line-height-computed;

			@media (max-width: @screen-xs) {
				width: 100%;
				margin: 0;
				margin-bottom: 10px;
			}
		}

		&.medium {
			float: right;
			width: 50%;
			margin-left: @line-height-computed;
			margin-bottom: @line-height-computed;

			@media (max-width: @screen-xs-max) {
				width: 100%;
				margin: 0;
			}
		}
	}

	.entryFooter {
		clear: both;
		width: 100%;
		margin-top: @line-height-computed;
		border-top: 1px solid #ccc;
		padding-top: @line-height-computed;
	}

	.more {
		display: block;
		white-space: nowrap;
		margin-top: 10px;
	}

	.back {
		float: left;
	}

	.more, .back {
		font-weight: bold;
		text-transform: uppercase;
		font-size: 14px;
	}

	.social {
		float: right;
	}

	.preview {
		img {
			width: 100%;
		}
	}

	.albumImages {
		list-style: none;
		overflow: hidden;
		margin: 30px 0;
		padding: 0;

		li {
			float: left;
			margin: 0 5px 5px 0;

			img {
				width: 170px;
			}
		}
	}
}
