/********************
* Grid
*********************/

#formbuilder .grid .invisible {
	color:#aaa;
}

/********************
* Status
*********************/

#formbuilder .status {
	border-top: 1px dotted #ddd;
	border-bottom: 1px dotted #ddd;
	margin-bottom:20px;
	color:#333;
	font-size: 90%;
	padding: 10px 0;
}
#formbuilder .status .bold {
	font-weight: 700;
}

div.elm_text .size_small, .elm_emailAddress .size_small  {
	width:100px;
}
div.elm_text .size_medium, .elm_emailAddress .size_medium {
	width:200px;
}
div.elm_text .size_large, .elm_emailAddress .size_large  {
	width:300px;
}
div.elm_textarea .size_small {
	height:100px;
}
div.elm_textarea .size_medium {
	height:200px;
}
div.elm_textarea .size_large {
	height:300px;
}
div.elm_radio span.lmnt label {
	display:inline;
	border:none;
	height:auto;
}
div.elm_captcha .captchaImg {
	margin-bottom: 5px;
}
div.elm {
	margin-bottom: 10px;
}
#formbuilder div.elm label {
	display:block;
}
div.elm label.hidden {
	display: none;
}

div.elm_checkbox label {
	display:inline;
}
div.elm .error {
	font-size:10px;
	background-color:#f8caca;
	border: 1px solid #73000e;
	padding:2px;
}
div.elm_controls {
	margin-top:10px;
}

#formbuilder div.elm_controls {
	clear:left;
}

.elm_multiCheckbox br {
	display:none;
}

fieldset.col2 {
	@media (min-width:@screen-md-min) {
		float:left;
		width:48%;
		margin-right: 2%;
	}
}

fieldset.col1 {
	clear:left;
}

fieldset.hideLegend legend {
	display:none;
}

fieldset.hideBorder {
	border: none;
	padding: 0;
}
form div.error {
	border:1px solid #73000e;;
	width:50%;
	padding:4px 8px;
	font-size:10px;
	background:#f8caca;
	margin:0;
}

textarea#multiOptions {
	min-height: 150px;
}
