.box_galleryimage {
	.potd {
		img {
			width:100%;
		}
	}
}

#gallery {
	img.tiny {
		max-width:64px;
	}
	
	br.clear {
		display: none;
	}

	.thumbs {
		margin-left: -2px;
		margin-right: -2px;
		overflow: hidden;
	}

	.entry {
		.info {
			margin-bottom: 10px;

			ul {
				padding: 0;
				margin: 0;
				list-style: none outside;
				overflow: hidden;
			}

			li {
				float: left;
				margin-right: 5px;

				&:before {
					content: '|';
					margin-right: 5px;
				}

				&:first-child {
					&:before {
						display: none;
					}
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.image {
			float: left;
			width: 25%;
			padding-left: 2px;
			padding-right: 2px;
			margin-bottom: 4px;

			@media (max-width: @screen-sm) {
				width: 50%;
			}

			@media (max-width: @screen-xs) {
				width: 100%;
			}

			img {
				width: 100%;
			}
		}
		
		.description {
			margin-bottom: 20px;
		}
	}

	.entries {
		.entry {
			.clearfix();
			border-bottom: 1px solid #ccc;
			padding-bottom: @line-height-computed;
			margin-bottom: @line-height-computed;
		}

		h4 {
			margin-top: 0;
			margin-bottom: 0;
		}

		.image {
			padding: 0;
			margin-right: 20px;
		}

		.title {
			> ul {
				margin: 0;
				margin-bottom: 10px;
				list-style: none;
				font-size: 12px;
				color: #999;
				overflow: hidden;
				display: block;

				li {
					float:left;
					padding: 0 6px;

					&:first-child {
						padding-left: 0;
					}
				}
			}
		}
	}
}

#gallery .entry .info hr {
	margin:16px 0 20px;
}

#gallery.single .back  {
	display:none
}
#gallery.single .image {
	text-align:center;
	margin-top:14px
}
#gallery .sortableAlbums {
	max-height: 400px;
	list-style: none outside;
	margin: 0;
	padding: 0;

	li {
		min-height:48px;
	}
}
#gallery .quickOrder {
	text-align: right;
	width: 98%;
}
#gallery .ordType {
	display: block;
	border: 1px solid #E3E3E3;
	border-bottom: none;
}
#gallery .ordType .radio {
	margin: 0 5px 0 5px;
	vertical-align: middle;
}
#gallery #sortList {
	border-top: none;
}

#imageManip {
	.before {
		img {
			width:100%;
		}
	}

	.rotate {
		li:first-child {
			float:left;
		}

		li:last-child {
			float:right;
		}
	}

	.clear {
		clear:both;
		margin-top:30px;
	}
}