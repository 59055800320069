@sm-menu-width: 320px;

#page {
	&:after {
		content: '';
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		max-width: 0;
		opacity: 0;
		z-index: @zindex-header + 1;
		background: rgba(0, 0, 0, 0.7);
		transition: .4s ease all;
		@media (max-width: @screen-xs-max) {
			display: none;
		}
	}

	.mainmenu-open & {
		&:after {
			opacity: 1;
			max-width: 9999em;
		}
	}
}

body.mainmenu-open {
	overflow: hidden;
}

#mainmenuToggle {
	.visible-xs-block();
	.visible-sm-block();

	position: fixed;
	z-index: @zindex-header + 3;
	top: 0;
	right: 0;
	width: @header-height-mobile;
	height: @header-height-mobile;
	cursor: pointer;
	padding: 13px 0;

	body.loggedIn & {
		top: 31px;
	}

	span {
		display: block;
		width: @header-height-mobile / 2;
		height: 3px;
		background: #fff;
		margin: 4px auto;
		transition: transform .3s ease 0s;

		.mainmenu-open & {
			background: @color-first;
			&.first {
				display: none;
			}
			&.middle {
				transform: translateY(8px) rotate(45deg);
			}
			&.last {
				transform: translateY(1px) rotate(-45deg);
			}
		}
	}
}

#mainmenu {
	@media (max-width: @screen-sm-max) {
		position: fixed;
		padding-top: @header-height-mobile;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: @zindex-header + 2;
		width: 100%;
		max-width: @sm-menu-width;

		overflow-y: auto;
		background: #ededed url('@{path-images}/mm-bg.png') no-repeat;
		background-size: 100% auto;
		transform: translateX(100%);
		transition: .4s ease all;

		transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);

		body.mainmenu-open & {
			transform: translateX(0);
		}

		.top-buttons {
			margin-left: -15px;
			margin-right: -15px;
			margin-bottom: 20px;
			padding: 15px;

			border-top: 1px solid #d3d3e4;
			border-bottom: 1px solid #d3d3e4;

			text-align: center;

			> a {
				display: inline-block;
			}

			.selected {
				opacity: 0.5;
			}

			.camera {
				margin-right: 10px;
			}
		}

		.mmLevel2 {
			font-size: 22px;
		}
	}
	@media (max-width: @screen-xs-max) {
		max-width: none;
	}
}