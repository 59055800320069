// The old clear
br.clear {
	clear:both;
}

// This was reset in the old reset
ul.menu {
	padding:0;
}

// Wider login form since we use padded buttons
div#auth {
	width:320px;

	input.text {
		width:100%;
	}
}

#employees .content {
	.clearfix();
}

#boxman_sections {
	overflow:hidden;

	.boxman_template {
		width:100%;
		max-width:300px;
	}
}

// TINYMCE: Fixes the button padding in the toolbar when using box-sizing
.mceLayout {
	td, a, th, tr {
		-webkit-box-sizing:content-box !important;
		-moz-box-sizing:content-box !important;
		box-sizing:content-box !important;
	}
}

body#tinymce table {
	width:100%;
	table-layout: fixed;

	td {
		font-size: 1em;
		font-family: inherit;
	}
}

.menu, .toolbar, .strevda ul {
	padding:0;
}

.hidden, .hide {
	display:none;
}

.noscreen {
	&:extend(.sr-only);
}
