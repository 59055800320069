.elm_Images {
	.images {
		.make-row();
		margin-left: -5px;
		margin-right: -5px;
	}

	.image {
		position: relative;
		.make-sm-column(3);
		.make-xs-column(12);
		padding-left: 5px;
		padding-right: 5px;
		margin-bottom: 5px;
		cursor: move;

		img {
			width: 100%;
			height: auto;
		}

		.actions {
			display: none;
			position: absolute;
			top: 0;
			right: 5px;
			background: rgba(255, 255, 255, 0.8);

			a {
				float: left;
				display: block;
				padding: 5px 8px;
			}

			img {
				width: auto;
				height: auto;
			}
		}

		&:hover .actions {
			display: block;
		}
	}
}

.has-dropzone input[type=file] {
	display:none;
}

.file-preview {
	position: relative;

	.darkroom-container {
		margin: 60px 0 @grid-gutter-width / 2;

		button {
			background: transparent !important;
		}
	}
}

.file-dropzone {
	background:@gray-lighter;
	border:1px solid #ccc;
	width:150px;
	padding:5px 20px;
	text-align:center;

	.fa {
		font-size:32px;
		color:#ccc;
		vertical-align:middle;
	}

	body.droppable & {
		border-color:magenta;
	}
}

.file-preview, .file-dropzone {
	margin-top: @grid-gutter-width / 2;
	clear: both;
}