#formbuilder {
	.moya_form {
		position: relative;

		label {
			font-weight: 600;
			font-size: 16px;
			padding-top: 6px;
			background: none;

			&.required {
				color: inherit;

				&:after {
					color: #c00;
				}
			}
		}
		.elm_radio {
			> .lmnt {
				clear: left;
				display: block;
			}
		}

		input[type=text],
		textarea {
			width: 100%;
			max-width: none;
			border: 1px solid #ccc;
			padding: 5px 10px 6px;
		}

		textarea {
			max-width: 100%;
		}

		.elm_captcha {
			display: flex;
			flex-wrap: wrap;
			label {
				flex: 0 0 100%;
			}
			input {
				min-width: 0;
			}
			.lmnt {
				flex: 1 1 25%;
			}
			.captchaImg {
				flex-grow: 0 !important;
				flex-basis: 140px !important;
				margin-left: 15px;
				margin-bottom: 0;
				order: 3;
				min-width: 140px;
			}
			input {
				height: 50px;
			}
		}

		#submitForm {
			.btn;
			.btn-primary;
			margin-top: 15px;
			float: right;
		}
	}
}
