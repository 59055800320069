#employees {
	.entryImage {
		float:right;
	}

	.sub {
		padding-left:@line-height-computed;
	}

	.staff {
		margin-bottom:@line-height-computed;
	}

	.branch {
		margin-bottom:0;
		padding-bottom:8px;
		clear:left;
	}

	.content {
		.image {
			float:left;
			width:25%;
			margin-right:@grid-gutter-width;

			img {
				width:100%;
			}
		}

		.actions {
			float:left;
			position:absolute;
			background:#fff;
			margin-top:3px;
			padding:0 3px 4px 3px;
			border-right:1px solid #ccc;
			border-bottom:1px solid #ccc;
			z-index:2;
		}
	}

	.noImage .actions {
		border:0;
		position:relative;
		float:none;
	}
}

#employees .toolbar,#employees .branch {
	border-bottom:1px solid #ccc;
}
#center #employees .branch a {
	color:#000
}
#employees .toolbar,#employees .staff {
	float:left;
	width:100%;
	clear:left;
}
#employees .open {
	padding:1px;
	outline:1px solid #ccc;
	border:1px solid #fff;
	background:#f2f2f2;
	-moz-box-sizing:border-box;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
}
#employees .toolbar li {
	float:left;
	display:block;
	padding:5px 0;
	margin-right:0;
	cursor:pointer;
}
#employees .open li {
	padding:3px 0 4px;
}
#employees .toolbar .first {
	width:40%;
	padding-left:4px;
}
#employees .open .first {
	padding-left:2px;
	padding-right:2px;
}
#employees .toolbar .middle {
	width:30%
}
#employees .toolbar .last {
	width:29%
}
#employees .staff .content {
	border:1px solid #ccc;
	border-top:0;
	padding:10px;
	clear:left;
	-moz-box-sizing:border-box;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	width:100%;
}
#employees .content .description {
	overflow:auto
}
#employees .content .extra li {
	display:inline-block;
	margin-right:10px;
}
#employee_tabs {
	label {
		height: @input-height-base;
		line-height: @input-height-base;
		margin-bottom: 10px;
	}

	input {
		margin-bottom: 10px;
	}

	.listAll {
		td, td, th {
			padding: 0;
			padding-bottom: 10px;
			vertical-align: middle;

			input {
				margin: 0;
			}
		}
	}
}