@font-face {
	font-family: 'thirsty_roughregular_one';
	src: url('@{font-path}yellow_design_studio_-_thirstyroughregone-webfont.eot');
	src: url('@{font-path}yellow_design_studio_-_thirstyroughregone-webfont.eot?#iefix') format('embedded-opentype'),
	url('@{font-path}yellow_design_studio_-_thirstyroughregone-webfont.woff2') format('woff2'),
	url('@{font-path}yellow_design_studio_-_thirstyroughregone-webfont.woff') format('woff'),
	url('@{font-path}yellow_design_studio_-_thirstyroughregone-webfont.ttf') format('truetype'),
	url('@{font-path}yellow_design_studio_-_thirstyroughregone-webfont.svg#thirsty_roughregular_one') format('svg');
	font-weight: normal;
	font-style: normal;
}