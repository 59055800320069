#gallery {
	.title {
		a {
			color: @color-first;

			&:hover {
				color: @color-third;
			}
		}
	}
}