.entries{
	margin:0;
}
.entry{
	list-style:none;
	margin-top:0.5em;
}
.entryTitle{
	margin:0 4px 0 0;
}
.entryContent{
	clear:left;
}
.entryFooter{
	clear:both;
}
.entryInfo{
	font-size:10px;
	clear:left;
}
.entries .entryInfo {
	clear: none;
}
.entryImage{
	float:left;
}
.entryImage img {
	border:0;
}
.entryImageText{
	max-width: 100%;
	padding:0 1em 1em;
	font-style:italic;
	text-align:center;
}
.entryResources li {
	list-style:none;
}
.entryLabel{
	font-weight:700;
}
.entryValue{
	margin-bottom:1em;
}
