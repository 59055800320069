#gallery {
	.entry {
		.info {
			display:none;
		}
	}
	.entries {
		.make-row();

		.entry {
			.make-xs-column(6);
			.make-sm-column(4);
			.make-md-column(3);
			@media (max-width:@screen-xs-min) {
				.make-xs-column(12);
			}
			border:0;
			margin-top:0;

			.image {
				float:none;
				width:100%;
				margin: 0 0 10px;
			}
			.title {
				h4 {
					font-weight:bold;

					a {
						.ellipsis(2,@font-size-base, 1.3);
					}
				}
			}
			.content {
				display:none;
			}
		}
	}
}