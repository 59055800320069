#mainmenu {
	.mmLevel1 {
		li.open {
			position:relative;

			.mmLevel2 {
				display:block;
				position:absolute;
				background: #fff;
				border: 1px solid #ccc;
				border-top: 0;
				left: 30px;
				right: 0;
				padding: 15px 0;
				z-index: 98;

				li {
					a {
						text-align:center;
						padding: 6px 10px;
						font-size: 14px;
					}
					a:hover,
					&.selectedLevel2 a {
						color:@color-second;
					}
					ul {
						display:none;
					}
				}
			}
		}
	}
}