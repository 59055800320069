.mceAlignRight {
	.pull-right;

	margin: 0 0 @line-height-computed @grid-gutter-width;
	clear: right;
}

.mceAlignLeft {
	.pull-left;

	margin: 0 @grid-gutter-width @line-height-computed 0;
	clear: left;
}
