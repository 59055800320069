#search {
	.debug {
		display: none;
	}

	#searchForm {
		position: relative;
		width: 50%;

		@media (max-width: @screen-sm) {
			width: 100%;
		}
	}

	#elm_q {
		label {
			display: none;
		}

		input {
			width: 100%;
			height: @input-height-base;
		}
	}

	#elm_t {
		display: none;
	}

	#elm_submit {
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
		width: @input-height-base;
		height: @input-height-base;

		&:before {
			position: absolute;
			right: 0;
			content: @fa-var-search;
			.fa-icon();
			color: @color-first;
			text-align: center;
			width: 100%;
			height: 100%;
			line-height: @input-height-base;
			font-size: 120%;
		}

		input {
			position: absolute;
			right: 0;
			background: none;
			border: 0;
			padding: 0;
			width: @input-height-base;
			height: @input-height-base;
			.text-hide();
		}
	}

	.term {
		color: #000;
		border-bottom: 1px solid #ccc;
		border-top: 1px solid #ccc;
		margin: 7px 0 14px;
		float: left;
		width: 100%;
		line-height: 37px;
		.clearfix();

		span {
			font-weight: bold;
		}

		.time {
			float: right;
		}
	}

	.searchResults {
		.debug {
			font-size: 75%;
			color: #999;
			text-align: right;

			span {
				display: inline-block;
				margin-right: 10px;
			}
		}

		> ol {
			&:extend(ol.reset);
			
			> li {
				.clearfix();
				display: block;
				border-bottom: 1px solid #ccc;
				padding-bottom: @grid-gutter-width / 2;
				margin-bottom: @grid-gutter-width / 2;

				&:last-child {
					border-bottom: 0;
					margin-bottom: 0;
				}
				
				> br {
					display: none;
				}
			}
		}
		
		.rank {
			display: none;
		}

		.img {
			width: 25%;
			float: left;
			margin-right: @grid-gutter-width / 2;
			
			@media (max-width: @screen-sm) {
				float: none;
				margin-right: 0;
				margin-bottom: @grid-gutter-width / 2;
				width: 100%;
			}

			img {
				.img-responsive();
			}
		}

		.title {
			font-size: 120%;
			margin-bottom: 5px;
		}

		em {
			font-weight: bold;
			font-style: normal;
		}

		.albumImages {
			list-style: none outside;
			padding: 0;
			margin: 0 -2px;
			padding-top: @grid-gutter-width / 2;
			overflow: hidden;

			> li {
				.make-sm-column(1);
				.make-xs-column(3);
				padding: 0 2px 0;
				margin-bottom: 4px;
			}

			img {
				.img-responsive();
				width: 100%;
			}
		}
	}
}