// Generate the xxs columns
@screen-xxs: @screen-xs-min;
@screen-xxs-max: (@screen-xs-min - 1);

.make-xxs-column(@columns; @gutter: @grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left:  (@gutter / 2);
	padding-right: (@gutter / 2);

	@media (max-width: @screen-xxs) {
		float: left;
		width: percentage((@columns / @grid-columns));
	}
}

.contentWrap {
	position: relative;
	display: block;
	max-width: @site-width + @grid-gutter-width;
	padding-left: @grid-gutter-width / 2;
	padding-right: @grid-gutter-width / 2;
	margin: 0 auto;
}

.polar-card {
	.inner {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
	.image {
		border: 1px solid #fff;
		border-bottom: 0;
		overflow: hidden;
		img {
			transition: .4s ease all;
			transform: translateZ(0);
		}
	}

	.info {
		padding: 20px 15px;
		color: #fff;
		text-align: center;
		background: @color-first;

		border: 1px solid #fff;
		border-top: 0;

		font-size: 15px;
		text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
		line-height: 1.33;

		cursor: pointer;

		transition: .4s ease all;
		a {
			color: #fff;
		}

		h3 {
			margin-top: 0;
			font-family: @font-family-thirsty;
			font-size: 30px;
		}
	}
	.inner:hover {
		.image img {
			transform: scale(1.05);
		}

		.info {
			background: @color-second;
		}
	}
}

.horse-seperator {
	.contentWrap();
	position: relative;
	margin-top: 60px;
	margin-bottom: 60px;

	svg {
		margin-left: auto;
		margin-right: auto;
		display: block;
	}

	&:before {
		content: '';
		position: absolute;
		display: block;
		top: 50%;
		height: 1px;
		left: 15px;
		right: calc(~'50% + 35px');
		background: linear-gradient(to left, #7e7e7b, rgba(0, 0, 0, 0));
	}

	&:after {
		content: '';
		position: absolute;
		display: block;
		height: 1px;
		top: 50%;
		right: 15px;
		left: calc(~'50% + 35px');
		background: linear-gradient(to right, #7e7e7b, rgba(0, 0, 0, 0));
	}
}

.arrow-list {
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;

	li {
		position: relative;
		padding-left: 20px;
		&:before {
			.fa-icon;
			content: @fa-var-chevron-right;
			position: absolute;
			left: 0;
			top: 8px;
			color: @color-second;
			font-size: 16px;
		}
	}
}
