body {
	display: flex;
	min-height: 100vh;
	-webkit-box-orient: vertical;
	flex-direction: column;

	&#tinymce, &.mceContentBody {
		display:block;
		flex-direction: initial;
	}
}

#page {
	flex: 1 0 auto;
}

#footerInfo {
	flex-shrink: 0;
}
