#mainmenu {
	position: absolute;
	top: 92px;
	width: 100%;

	.level1 {
		font-family: @font-family-thirsty;
		font-size: 30px;
	}

	a {
		color: @color-first;
		display: block;
		text-align: center;
		padding: 6px;
		&:hover {
			color: @color-second;
		}
	}

	.selectedLevel1 > a,
	.selectedLevel2 > a {
		color: @color-second;
	}

	@media (min-width: @screen-md) {
		.contentWrap {
			padding-left: 230px;
		}
		.mmLevel1 {
			display: flex;
			justify-content: flex-end;
		}
		.level1 {
			flex: 0 0 auto;
			padding-left: 30px;
		}
		.mmLevel2 {
			display: none;
		}
	}
}
