.frontpage {
	#main {
		background: url("@{path-images}frontpage-bg.jpg") no-repeat;
		background-size: 100% auto;
		background-position: 0 60vh;
		

		@media (min-width: 1500px) {
			background-position: 0 260px;
		}
		@media (max-width: @screen-sm-max) {
			background-position: 0 40vh;
		}
		@media (max-width: @screen-xs-max) {
			background-position: 0 20vh;
		}
	}

	h2 {
		margin: 55px auto 60px;
	}

	@media (max-width: @screen-sm-max) {
		.elves,
		.soon {
			h2 {
				span {
					display: block;
				}
			}
		}
	}

	@media (max-width: @screen-xs-max) {
		h2 {
			span {
				display: block;
			}
		}
	}

	.elves,
	.welcome,
	.soon {
		text-align: center;
		font-family: @font-family-thirsty;
	}

	.elves,
	.welcome {
		.boxText {
			.contentWrap;
			z-index: 1;
		}
	}

	.elves h2 {
		font-size: 75px;
	}

	.welcome,
	.soon {
		h2 {
			font-size: 60px;
		}
	}

	@media (max-width: @screen-xs-max) {
		.elves,
		.welcome,
		.soon {
			h2 {
				font-size: 36px;
			}
		}
	}

	.box4 {
		.contentWrap();
		margin-top: 40px;

		ul {
			margin-left: -15px;
			margin-right: -15px;
			display: flex;
			flex-direction: row;
			justify-content: center;
		}

		li {
			.polar-card();
			display: flex;
			flex-wrap: wrap;
			padding-left: 15px;
			padding-right: 15px;
			flex-direction: column;
			max-width: 300px;
			flex: 0 0 25%;

			.image {
				flex-grow: 0;
			}

			.info {
				flex: 1;
			}
		}

		@media (max-width: @screen-sm-max) {
			ul {
				flex-wrap: wrap;
				max-width: 600px;
				margin-left: auto;
				margin-right: auto;
			}

			li {
				flex: 0 0 50%;
				margin-bottom: 30px;
			}
		}

		@media (max-width: @screen-xs-max) {
			li {
				flex: 0 0 100%;
			}
		}
	}

	.soon {
		position: relative;
		z-index: 1;

		h2 {
			margin-bottom: 0;
			margin-top: 75px;
		}

		margin-bottom: -120px;
	}

	.experience {
		.contentWrap();

		h3 {
			font-weight: 700;
			font-size: 30px;
		}

		.btn {
			margin-top: 15px;
			background: transparent;
			border: 1px solid @color-first;
			color: @color-first;

			&:hover {
				color: #fff;
				background: @color-first;
			}
		}

		.boxText {
			.clearfix();
		}

		.video-wrap {
			background: #fff;
			padding: 3px;
			box-shadow: 0px 0px 15px #a5a5a5;

			@media (min-width: @screen-md) {
				width: 50%;
				float: right;
				margin-left: 50px;
				margin-top: 30px;
			}
			.video {
				@media (max-width: @screen-sm-max) {
					margin-right: auto;
					margin-left: auto;
				}
			}
		}
	}

	.lower-banner {
		position: relative;
		margin-top: 20px;

		li {
			background-position: center center;
			background-size: cover;
			@media (max-width: @screen-xs-max) {
				min-height: 400px !important;
			}
		}
	}
}
