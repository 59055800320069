img.icon {
	vertical-align:middle;
}
a .icon {
	border:0;
}
.jip .icon {
	margin-right:4px;
}

li.icon{
	padding-left:10px;
}

li.icon > a {
	background-image:url('/_moya/icons/Package.png') !important;
	background-repeat: no-repeat !important;
	background-position: 10px 50% !important;
}

li.icon.news > a {background-image:url('/_moya/icons/News.png') !important;}
li.icon.gallery > a {background-image:url('/_moya/icons/Photo_Double.png') !important}
li.icon.elfinder > a,li.icon.fileman > a {background-image:url('/_moya/icons/File_Closed.png') !important;}
li.icon.page > a {background-image:url('/_moya/icons/Document.png') !important;}
li.icon.mainmenu > a {background-image:url('/_moya/icons/Relationship_Data.png') !important;}
li.icon.sitemap > a {background-image:url('/_moya/icons/Relationship_Data.png') !important;}
li.icon.user > a {background-image:url('/_moya/icons/User.png') !important;}
li.icon.users > a {background-image:url('/_moya/icons/Group.png') !important;}
li.icon.settings > a {background-image:url('/_moya/icons/Settings.png') !important;}
li.icon.logout > a {background-image:url('/_moya/icons/Arrow_Undo.png') !important;}
li.icon.strevda > a {background-image:url('/_moya/icons/Schedule_Verb.png') !important;}
li.icon.fyrirspurnir > a {background-image:url('/_moya/icons/Contact.png') !important;}
li.icon.konnun > a {background-image:url('/_moya/icons/Chart_Bar.png') !important;}
li.icon.search > a {background-image:url('/_moya/icons/Search.png') !important;}
li.icon.tube > a {background-image:url('/_moya/icons/Movie.png') !important;}
li.icon.user > a {background-image:url('/_moya/icons/User.png') !important;}
li.icon.mailinglist > a {background-image:url('/_moya/icons/Entry.png') !important;}
li.icon.rssreader > a {background-image:url('/_moya/icons/RSS.png') !important;}
li.icon.forums > a {background-image:url('/_moya/icons/Conversation.png') !important;}
li.icon.employees > a {background-image:url('/_moya/icons/Group.png') !important;}
li.icon.division > a {background-image:url('/_moya/icons/Hierarchy.png') !important;}
li.icon.mos > a {background-image:url('/_moya/icons/Shopping_Cart_Secure.png') !important;}
li.icon.look > a {background-image:url('/_moya/icons/Colour_Palette.png') !important;}
li.icon.logger > a {background-image:url('/_moya/icons/History.png') !important;}
li.icon.efnisflokkar > a {background-image:url('/_moya/icons/Category.png') !important;}
li.icon.boxman > a {background-image:url('/_moya/icons/Schedule_Noun.png') !important;}
li.icon.module > a {background-image:url('/_moya/icons/Module.png') !important;}
li.icon.formbuilder > a {background-image:url('/_moya/icons/Form.png') !important;}
