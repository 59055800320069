.paginationControl {
	margin: 20px 0;

	.pagerContent {
		> a,
		> span {
			display:inline-block;
			padding:4px 10px;
			background:#f3f3f3;
		}
	}
}