/** PRE-DEFINED **/

.thin {	width:1%; }
.column{ float:left; }
.w50{ width:49%; }
.w33{ width:33%; }

.menu, .toolbar{
	margin:0;
	list-style:none outside;
}

.horizontal li, .toolbar li{
	display:inline;
	margin-right:1em;
}

.required {
	color:#822;
}
.required:after {
	content: " *";
}

.altRow2{
	background:#f7f7f7;
	border-top:1px solid #eee;
	border-bottom:1px solid #eee;
}

/** SOON TO DIE **/

.listAll{
	width:100%;
}
.listAll td, .listAll th{
	padding:4px;
}
.listAll th {
	text-align:left;
}
.toolCol {
	text-align:right;
	width:1%;
	white-space:nowrap;
}
.rusl, #rusl {
	display:none;
}
#captcha, .captchaImg {
	display:block;
}
label.inline{
	float:left;
	display:block;
	width:120px;
	clear:left;
}
ul.categories{
	list-style:none;
	display:inline;
	margin:0;
}
/*loading overlay*/
.waiting {
	position: relative;
}
.waiting .overlay {
	text-align: center;
	width:100%;
	height:100%;
	z-index: 100;
	background: #fff url(/_w/elrte/images/loading.gif) no-repeat 50% 200px;
	position: absolute;
	top:0;left:0;
	visibility: visible;
}
.waiting li {
	list-style: none;
}

#userAttributes {
	width:50%;
}
#userAttributes td {
	padding:4px;
}
#userAttributes input {
	width:100%;
}

/*
To fix elfinder opened from tinymce.
JQuery UI should now default to 200020 as base zIndex.
TinyMCE InlinePopupus plugin uses 300000 as base zIndex.
So we force elFinder to base at 400010.
This way we get the correct stack order without using jquery dialog stack stuff.
*/
.ui-front {
	z-index: 200020 !important;
}
.ui-widget-overlay.ui-front {
	z-index: 200010 !important;
}
.ui-front.el-finder-dialog {
	z-index: 400010 !important;
}
