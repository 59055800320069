@grid-row1-bg: #fff;
@grid-row2-bg: #f7f7f7;
@grid-row-hover: #eee;
@grid-row-border: #ddd;
@grid-header-border: #ccc;
@grid-header-bg: #E6E6E6;
@grid-filterInputs-bg: #f6f6f6;

.grid br.clear {
	display: none;
}

.grid table {
	width:100%;
	border-collapse:separate;
	border-bottom:1px solid @grid-header-border;
}
.grid table th, .grid .filter-inputs td {
	background-color:@grid-header-bg;
	border-left:1px solid @grid-header-border;
	border-right:1px solid transparent;
	margin-bottom:5px;
	text-align:left;
}
.grid table th.last, .grid .filter-inputs td.last {
	border-right:1px solid @grid-row-border;
}
.grid table td {
	border-left:1px solid @grid-row-border;
	padding: 5px 10px;
	border-bottom: 1px solid @grid-row-border;
}
.grid table td.last {
	border-right:1px solid @grid-row-border;
}
.grid tr.altRow2 {
	background:@grid-row2-bg none repeat scroll 0 0;
}
.grid tr.altRow1 {
	background:@grid-row1-bg none repeat scroll 0 0;
}
.grid tbody tr {
	transition: background-color .3s ease;
	&:hover {
		background-color: @grid-row-hover;
	}
	&:last-child {
		td {
			border-bottom: 1px solid transparent;
		}
	}
}
.grid .sHidden {
	color:gray;
	font-style:italic;
}

/* Filter */

.grid .filter-buttons {
	float: right;
	margin-top: -43px;

	img {
		margin: 5px 5px 5px 2px;
	}
}

.grid .filter-inputs {
	td {
		background-color: @grid-filterInputs-bg;
		border-bottom:1px solid @grid-row-border;
		padding: 10px;
		height: auto;
	}

	input, select {
		width: 100%;
		min-width: 0;
		max-width: 250px;
	}

	.filter-date {
		max-width: 150px;
		dl {
			margin: 0;
		}

		dt {
			float: left;
			display: block;
			width: 25%;
			clear: left;
		}

		dd {
			width: 75%;
			float: left;
			display: block;
		}

		label {
			line-height: @input-height-base;
			font-size: 13px;
		}
	}
}

/* Sorter */
.grid .sorter {
	th {
		padding: 6px 12px;
	}

	a.sorter-anchor {
		display: block;
		text-decoration: none;

		.ui-icon {
			float: right;
			margin-top: 2px;
		}
	}
}

/* Paginator */
.grid .paginator .spacer {
	color:#ccc;
	margin:0 4px;
}

.grid .paginator {
	margin-top: 10px;
	margin-bottom: 10px;

	select {
		min-width: 0;
		width: auto;
		margin-right: 10px;
	}
}

#grid-sitemap_idx-form, #grid-modules-form, #grid-formbuilder_design-form {
	.filter-buttons {
		display: none;
	}
}