.flex-row {
	display: flex;
	flex-flow: row wrap;
	.make-row();

	.loop(@counter) when (@counter > 0) {
		.loop((@counter - 1));
	  	@media (min-width: @screen-md-min) {
		  &.flex-width-@{counter}0,
		  &.flex-width-desktop-@{counter}0 {
			width: (10% * @counter);
		  }
		}
		@media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
			&.flex-width-tablet-@{counter}0 {
				width: (10% * @counter);
				flex: initial;
			}
		}
		@media (max-width: @screen-xs-max) {
			&.flex-width-mobile-@{counter}0 {
				width: (10% * @counter);
				flex: initial;
			}
		}
	}

	.flex-column {
		padding-right: (@grid-gutter-width / 2);
		padding-left: (@grid-gutter-width / 2);

		&.flex-width-auto,
		&.flex-width-desktop-auto {
			flex: 1;
		}
		@media (max-width: @screen-sm-max) {
			&.flex-width-tablet-auto {
				width: auto;
				flex: 1;
			}
		}
		@media (max-width: @screen-xs-max) {
			&.flex-width-mobile-auto {
				width: auto;
				flex: 1;
			}
		}
		.loop(10);
	}

	//todo mobile code
}
