.message .error,
.message .success,
.message .fatalerror,
.message .warning,
.message .notice {
	border-style:solid;
	border-width:1px;
	padding:12px 12px 12px 48px;
	color:#111;
}
.message .fatalerror {
	background:#411419 url('/_moya/images/modules/Stop.png') no-repeat 8px 50%;
	border-color:#8e111e;
	font-weight:700;
	color:#fff;
}
.message .error {
	background:#f8caca url('/_moya/images/modules/Close.png') no-repeat 8px 50%;
	border-color:#73000e;
}
.message .success {
	background:#cec url('/_moya/images/modules/Confirm.png') no-repeat 8px 50%;
	border-color:#52894e;
}
.message .warning{
	background:#fbf4de url('/_moya/images/modules/Warning.png') no-repeat 8px 50%;
	border-color:#e0d2a3;
}

.message .notice {
	background:#dbe5f4 url('/_moya/images/modules/Info.png') no-repeat 8px 50%;
	border-color:#5e7b8f;
}