#header {
	.clearfix();
	position: relative;

	background: url("@{path-images}header-bg.jpg");
	background-size: cover;
	background-position: center center;

	box-shadow: 0 1px 5px 2px rgba(0,0,0,0.3);

	@media (min-width: @screen-md) {
		height: 165px;

		.logo {
			position: absolute;
			padding-top: 20px;
			padding-bottom: 20px;
			z-index: @zindex-header-logo;
			img {
				width: 200px;
			}
		}

		.frontpage & {

			.logo img {
				width: 261px;
			}
		}
	}

	// Mobile header
	@media (max-width: @screen-sm-max) {
		position: fixed;
		z-index: @zindex-header;
		top: 0;
		left: 0;
		width: 100%;
		height: @header-height-mobile;
		margin: 0;

		body.loggedIn & {
			top: 31px;
		}

		background: @color-first;

		.logo {
			margin-top: 10px;
			img {
				width: 105px;
				overflow: visible;
			}
		}
	}

	@media (max-width: @screen-xs-max) {
		.contentWrap {
			padding-right: 10px;
			padding-left: 10px;
		}

		.logo {
			margin-top: 8px;
			img {
				width: 65px;
			}
		}
	}
}

#qn {
	.contentWrap;
	position: absolute;
	top: 15px;
	.hidden-xs;
	.hidden-sm;

	// No menu on frontpage so we move qn down
	.frontpage & {
		top: 40px;
	}
	right: 0;
	left: 0;
	text-align: right;
	font-size: 18px;
	font-weight: 500;
	line-height: 50px;

	a {
		color: @color-first;
		&:hover {
			color: @color-second;
		}
	}

	.selected {
		opacity: 0.5;
	}

	ul {
		display: inline-block;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;

		li {
			display: inline-block;
		}

		// Links
		&:first-child {
			text-transform: uppercase;
			li {
				position: relative;
				padding-left: 20px;
				padding-right: 20px;
				&:not(:last-child):after {
					content: '|';
					position: absolute;
					right: -3px;
				}
			}
		}

		// Flags
		&:last-child {
			margin-left: 11px;
			li {
				padding-left: 3px;
				padding-right: 3px;
				transition: .4s ease all;
				&:hover {
					opacity: 0.7;
				}
			}
		}
	}
}

.camera {
	position: relative;
	display: inline-block;
	width: 46px;
	height: 46px;
	border-width: 2px;
	border-color: rgb(54, 71, 140);
	border-style: solid;
	border-radius: 50%;
	background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(78, 202, 245) 100%);

	color: transparent;
	font-size: 0;

	vertical-align: top;

	&:after {
		.fa-icon;
		content: @fa-var-video-camera;
		position: absolute;
		top: 10px;
		right: 0;
		left: 0;
		z-index: 1;
		text-indent: 0;
		text-align: center;
		font-size: 22px;
		color: @color-first;
	}

	transition: .4s ease all;
	&:hover {
		opacity: 0.7;
	}
}