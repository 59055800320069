#owl-subbanner {
	position: relative;
	.noBanner & {
		display: none;
	}
	.image {
		height: 430px;
		background-size: cover;
		background-position: center center;
	}

	@media (max-width: @screen-xs-max) {
		.image {
			height: 150px;
		}
	}

	&:after {
		content: '';
		position: absolute;
		right: 0;
		bottom: -18px;
		left: 0;
		height: 32px;
		background: url('@{path-images}/banner-pattern.png') no-repeat;
		background-position: center center;
		background-size: 100% auto;
	}
}