/********************
* Payment
*********************/
#formbuilder a#fallback {
  display: none;
}
#formbuilder .loader {
  text-align: center;
}
#formbuilder .loader h3 {
  font-weight: bold;
  width: 400px;
  text-align: center;
  margin: 0 auto;
}
#formbuilder .loader img {
  margin-top: 50px;
  margin-bottom: 30px;
}

#formbuilder .elm_payment {
  overflow: auto;
}
#formbuilder .elm_payment label.quantity {
  clear: both;
  float: left;
  margin-right: 10px;
}
#formbuilder .elm_payment input {
  float: left;
  width: 35px;
}
#formbuilder .elm_payment .price {
  float: left;
}
